.withdraw-dialog {
  &-header {
    display: flex;
    align-items: center;
    height: 22px;
    border-bottom: 1px solid #e8ebef;
    padding: 16px 0;
    &-title {
      padding-left: 24px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 500;
    }
    &-close-btn {
      margin-left: auto;
      width: 16px;
      display: flex;
      align-items: center;
      margin-right: 24px;
      &-icon {
        path {
          fill: #c2c2c2;
        }
        &:hover path {
          fill: #666666;
        }
      }
    }
  }
  &-content {
    display: flex;
    align-items: center;
    padding-top: 16px;
    height: 235px;
    flex-direction: column;
    &-row {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 14px;
      color: #000;
      &-label {
        width: 144px;
        margin-right: 12px;
        text-align: right;
        height: 14px;
      }
      &-input {
        width: 194px;
        height: 28px;
        margin-right: 154px;
        border-color: rgba($color: #000000, $alpha: 0.25);
        border-width: 1px;
        padding-left: 10px;
      }
      &-seccode {
        width: 194px;
        height: 28px;
        margin-right: 12px;
        border-color: rgba($color: #000000, $alpha: 0.25);
        border-width: 1px;
        padding-left: 10px;
      }
      &-value {
        width: 210px;
        height: 14px;
        text-align: left;
        margin-right: 154px;
      }
      &-seccodeUnSend {
        width: 102px;
        margin-right: 40px;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        color: #515151;
        cursor: pointer;
        &:hover {
          white-space: nowrap;
          font-size: 14px;
          color: #29b758;
        }
      }
      &-seccodeSended {
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        color: #d8d8d8;
        width: 102px;
        margin-right: 40px;
      }
    }
  }
  &-errInfo {
    line-height: 14px;
    font-size: 14px;
    height: 14px;
    color: #f75252;
    margin: 0px 40px 24px 40px;
  }
  &-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    height: 32px;
    border-top: 1px solid #e8ebef;
    padding: 10px 16px;
    &-cancel,
    &-confirm {
      width: 68px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    &-confirm {
      background-color: #29b758;
      height: 32px;
      color: #fff;
      &:hover {
        background-color: rgba(41, 183, 88, 0.8);
      }
    }
    &-confirm-disable {
      width: 68px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: default;
      background-color: #c5c5c5;
      height: 32px;
      color: #515151;
    }
    &-cancel {
      border: 1px solid #cccccc;
      color: #515151;
      height: 30px;
      &:hover {
        border: 1px solid #19af5d;
        color: #19af5d;
      }
    }
  }
}

.withdraw-dialog-content-row-input:-ms-input-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}
.withdraw-dialog-content-row-input::-webkit-input-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}

.withdraw-dialog-content-row-input:-moz-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}

.withdraw-dialog-content-row-input::-moz-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}

.withdraw-dialog-content-row-seccode:-ms-input-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}
.withdraw-dialog-content-row-seccode::-webkit-input-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}

.withdraw-dialog-content-row-seccode:-moz-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}

.withdraw-dialog-content-row-seccode::-moz-placeholder {
  color: rgba($color: #000000, $alpha: 0.25);
}
