body {
  height: calc(100vh - 79px);
}

.memberContent {
  margin: 79px 0 0 15vw;
  width: 85vw;
  background-color: #fff;
}

.memberTemContainer {
  margin: 0 auto;
  width: 85vw !important;
  margin: 0 auto;
  height: calc(100vh - 79px);
}

.contentBox {
  float: left;
  width: 71%;
  height: 640px;

  .titleDom {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 140px;

    .changeProfile {
      font-size: 14px;
      color: #19af5d;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .info {
    &-form {
      margin-left: 70px;

      input {
        box-sizing: border-box !important;
      }
    }

    &-tip {
      margin-left: 140px;
      margin-top: 10px;
      font-weight: bold;
      color: #9b9b9b;
    }

    &-button {
      margin-left: 140px;
      margin-top: 20px;
      width: 420px;
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        box-sizing: border-box !important;
      }

      .changed-btn,
      .not-changed-btn {
        width: 150px;
        height: 32px;
        line-height: 32px;
        font-weight: bold;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .changed-btn {
        color: white;
        background: #29b758;
        border: #29b758 solid 1px;
      }

      .not-changed-btn {
        color: #cccccc;
        background: #f0f0f0;
        border: #cccccc solid 1px;
        outline: medium;
      }
    }
  }

  .infoBoxContainer {
    margin-top: 30px;

    &-firstLine {
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      cursor: default;

      &-card {
        border-radius: 10px;
        margin: 0 10px;
        flex: 1;
        height: 164px;
        position: relative;

        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }

    &-secondLine {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;

      &-title {
        margin: 20px 30px 16px;
        font-size: 16px;
        color: #515151;
        font-weight: bolder;
      }

      &-form {
        position: relative;
        padding-bottom: 38px;

        .memberform-btn {
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          color: #515151;
          cursor: pointer;

          &:hover {
            color: #19af5d;

            .memberform-btn-pull-icon path {
              fill: #19af5d;
            }
          }

          &-pull {
            width: 10px;
            height: 10px;
            display: flex;
            align-items: center;
            margin-left: 2px;

            &-icon path {
              fill: #515151;
            }
          }
        }
      }
    }

    .upload {
      &-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 28px 22px 10px 33px;
      }

      &-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 70px;
        margin-right: 15px;

        &-count {
          font-size: 24px;
          line-height: 24px;
          color: #000;
          margin: 1px 0px;
          max-width: 65px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &:hover {
            cursor: default;
          }
        }

        &-tip {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          height: 20px;

          &-icon {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }

          &-content {
            font-size: 12px;
            line-height: 12px;
            color: #626262;
          }
        }
      }
    }

    .wallet {
      &-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 28px 37px 10px 33px;
      }

      &-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: auto;

        &-count {
          font-size: 24px;
          line-height: 24px;
          color: #000;
          margin: 1px 0px;
        }

        &-tip {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          height: 20px;

          &-icon {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }

          &-content {
            font-size: 12px;
            line-height: 12px;
            color: #626262;
          }
        }
      }
    }

    .card {
      &-title {
        margin: 16px 0 16px 20px;
        font-size: 16px;
        font-weight: bolder;
        color: #515151;
      }

      &-content-container {
        margin: 16px 16px 0 20px;
      }

      &-content {
        font-size: 14px;
        color: #777777;

        &-row {
          line-height: 14px;
          margin-bottom: 12px;
        }

        .vip-info {
          display: flex;
          flex-direction: row;
          align-items: center;

          .vip-icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .is-vip {
            font-weight: bold;
            font-size: 14px;
            color: #3c3938;
          }
        }

        .upload-info {
          display: flex;
          flex-direction: row;
          align-items: center;

          &-likeCount,
          &-downloadCount {
            height: 16px;
            width: 16px;
            margin-right: 8px;

            &-icon path {
              fill: #aaaaaa;
            }
          }
        }
      }

      &-icon {
        width: 46px;
        height: 46px;
        position: absolute;
        right: 16px;
        top: 10px;
      }

      &-button {
        position: absolute;
        right: 16px;
        bottom: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        color: #515151;

        &:hover {
          .card-button-pull-icon path,
          .card-button-pull-icon-upload-tip path,
          .card-button-pull-icon-wallet-tip path,
          .card-button-pull-icon-vip-tip path {
            fill: #19af5d;
          }

          &,
          .vip-tip,
          .upload-tip,
          .wallet-tip {
            color: #19af5d;
          }
        }

        .upload-tip {
          color: #ee5069;
        }

        .wallet-tip {
          color: #2ed679;
        }

        .vip-tip {
          color: #fba80d;
        }

        .card-button-pull-icon-upload-tip path {
          fill: #ee5069;
        }

        .card-button-pull-icon-wallet-tip path {
          fill: #2ed679;
        }

        .card-button-pull-icon-vip-tip path {
          fill: #fba80d;
        }

        &-pull {
          height: 10px;
          width: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 2px;

          &-icon path {
            fill: #515151;
          }
        }
      }
    }
  }
}

.foot .Tem_Container1 {
  background: #f9f9f9;
}

.member {
  &-container {
    width: 65vw;
    height: 640px;
    float: left;

    .page_box {
      margin-top: 30px;

      .pagination-btn.pagination-disabled {
        background: #eaefea;

        &:hover {
          background: #65c886;
        }
      }
    }
  }

  &-title {
    padding-left: 30px;
    margin-top: 24px;
    font-size: 24px;
    color: #515151;
  }

  &-tab {
    display: flex;
    margin: 30px 30px 16px;

    .tab-item {
      margin-right: 30px;
      padding-bottom: 4px;
      color: #515151;
      cursor: pointer;

      &:hover {
        color: #19af5d;
      }
    }

    .tab-selected {
      border-bottom: 2px solid #19af5d;
      color: #19af5d;
    }
  }

  &-trade {
    display: flex;
    margin: 30px 30px 16px;
    align-items: center;
    height: 32px;

    &-title {
      font-size: 16px;
      line-height: 16px;
      color: #515151;
      width: 78px;
      font-weight: bold;
    }

    &-money {
      font-size: 20px;
      line-height: 20px;
      color: #faad14;
      width: 160px;
      margin-right: 24px;
    }

    &-withdrawBtn {
      height: 32px;
      width: 86px;
      margin-right: 16px;
      background-color: #29b758;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: rgba(41, 183, 88, 0.8);
      }
    }

    &-withdrawBtn-disable {
      height: 32px;
      width: 86px;
      margin-right: 16px;
      background-color: #c5c5c5;
      color: #515151;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
    }

    &-tip {
      font-size: 12px;
      line-height: 15px;
      width: 160px;
      color: rgba($color: #000000, $alpha: 0.5);
    }

    &-priceTip {
      display: flex;
      align-items: center;

      &-icon {
        margin-left: 2px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        position: relative;

        &:hover {
          .member-trade-priceTip-tooltip {
            display: flex;
          }
        }

        &-image path {
          fill: rgba($color: #000000, $alpha: 0.5);
        }

        &-image circle {
          fill: rgba($color: #000000, $alpha: 0.5);
        }
      }

      &-tooltip {
        position: absolute;
        top: 17px;
        left: 1px;
        min-width: 165px;
        background-color: #fff;
        display: none;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 999;
        padding: 6px 8px;
        border-radius: 2px;
        box-shadow: 0 0 10px rgba(81, 81, 81, 0.12);
        font-size: 12px;
        font-weight: 500;

        & > div {
          line-height: 16px;
          color: #515151;
        }
      }
    }
  }

  &-filter {
    margin-left: 30px;
    display: flex;
    align-items: center;

    &-uploadBtn {
      height: 32px;
      width: 86px;
      margin-right: 40px;
      background-color: #29b758;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: rgba(41, 183, 88, 0.8);
      }
    }

    &-tab {
      display: flex;

      &-item {
        margin-right: 20px;
        cursor: pointer;
        color: #9b9b9b;

        &:hover {
          color: #19af5d;
        }
      }
    }

    .filter-selected {
      color: #19af5d;
    }
  }

  &-table {
    margin-left: 30px;
    margin-top: 16px;

    .project-table {
      &-name:hover {
        cursor: pointer;
      }

      .light-row {
        background-color: #fff;
      }

      .dark-row {
        background-color: #fafafa;
      }

      .status-passed {
        color: #19af5d;
      }

      .status-notpass {
        color: #ec2b2b;
        display: flex;
        align-items: center;

        &-icon {
          margin-left: 2px;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          position: relative;

          &:hover {
            .status-notpass-tooltip {
              display: flex;
            }
          }
        }

        &-tooltip {
          position: absolute;
          top: 17px;
          left: 1px;
          min-width: max-content;
          max-width: 300px;
          background-color: #fff;
          display: none;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          z-index: 999;
          padding: 6px 8px;
          border-radius: 2px;
          box-shadow: 0 0 10px rgba(81, 81, 81, 0.12);

          & > div {
            line-height: 22px;
            color: #515151;
          }
        }
      }

      .status-pending {
        color: #faa10a;
      }

      .table-button {
        &-container {
          display: flex;

          .table-normal {
            &-disabled {
              cursor: default;
              color: #c5c5c5;

              .table-normal-icon path {
                fill: #c5c5c5;
              }
            }

            &:hover {
              color: #19af5d;

              .table-normal-icon path {
                fill: #19af5d;
              }
            }
          }

          .table-delete {
            &:hover {
              color: #ec2b2b;

              .table-delete-icon path {
                fill: #ec2b2b;
              }
            }
          }
        }

        &-item {
          margin-right: 16px;
          display: flex;
          align-items: center;
          width: max-content;
          color: #515151;
          cursor: pointer;

          &-icon {
            height: 16px;
            width: 16px;
            margin-right: 2px;

            path {
              fill: #515151;
            }
          }
        }
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;

  & .order-id {
    width: 297px;
    padding: 0 0 0 10px;
  }

  & .order-name {
    width: 168px;
    padding: 0 0 0 10px;
  }

  & .order-name-cell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & .order-total-pay {
    width: 106px;
    padding: 0 0 0 10px;
  }

  & .order-create-time {
    width: 171px;
    padding: 0 0 0 10px;
  }

  & .order-operate {
    width: 123px;
    padding: 0 0 0 10px;
  }

  & .th2 {
    width: 1px;
    background: white;
    padding: 0;
  }
}

.orderForm {
  margin-top: 30px;

  & .tr1 {
    height: 30px;
    line-height: 30px;
    background: #f5f5f5;
    font-size: 14px;
  }

  & .tr2 {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  & table tr td {
    padding: 0 0 0 10px;
  }
}

.ant-table-thead > tr > th {
  background-color: #f2f3f4 !important;
  margin-right: 1px;
  font-weight: bolder;
  color: #515151;
  border: none;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  height: 100%;
  background-color: #fff;
  width: 2px;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 7px 10px 6px;
  height: 22px;
}

.ant-table-tbody > tr > td {
  border: none;
}

.ant-table-cell-row-hover {
  background: #eeeeee !important;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item-link {
  box-sizing: border-box !important;
}

.ant-tooltip-placement-topLeft {
  .ant-tooltip-inner {
    min-width: 30px;
    min-height: 14px;
    color: #515151;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    border-radius: 3px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  }
}
