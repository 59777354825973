.memberform {
  &-tab {
    display: flex;
    margin: 0 30px 16px;
    .tab-item {
      margin-right: 30px;
      padding-bottom: 4px;
      color: #515151;
      cursor: pointer;
      &:hover {
        color: #19af5d;
      }
    }
    .tab-selected {
      border-bottom: 2px solid #19af5d;
      color: #19af5d;
    }
  }
  &-table {
    margin-left: 30px;
    margin-top: 16px;
    .light-row {
      background-color: #fff;
    }
    .dark-row {
      background-color: #fafafa;
    }
  }
}
