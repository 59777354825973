.dialog {
  &-hint {
    display: flex;
    align-items: center;
    margin-top: 36px;
    justify-content: space-between;
    &-icon {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
    &-content {
      font-size: 16px;
      line-height: 30px;
      color: #515151;
    }
  }
  &-close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &-icon {
      path {
        fill: #c2c2c2;
      }
      &:hover path {
        fill: #666666;
      }
    }
  }
  &-bottom {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-cancel,
    &-confirm {
      width: 86px;
      height: 32px;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-confirm {
      background-color: #29b758;
      margin-left: 20px;
      color: #fff;
      &:hover {
        background-color: rgba(41, 183, 88, 0.8);
      }
    }
    &-cancel {
      border: 1px solid #cccccc;
      color: #515151;
      height: 30px;
      &:hover {
        border: 1px solid #19af5d;
        color: #19af5d;
      }
    }
    &-desc {
      margin-right: 16px;
      font-size: 14px;
      color: #9b9b9b;
      display: flex;
      &:hover {
        cursor: pointer;
        color: #29b758;
      }
      &:hover &-btn-icon path {
        fill: #29b758;
      }
      &-btn {
        margin-left: 4px;
        width: 10px;
        height: 10px;
        &-icon path {
          fill: #9b9b9b;
        }
      }
    }
    &-vipBtn {
      width: 104px;
      height: 32px;
      box-sizing: border-box;
      border: 1px solid #29b758;
      border-radius: 4px;
      font-size: 14px;
      color: #29b758;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
        background: #29b758;
        color: white;
      }
    }
  }
}
