html {
  width: 100%;
  height: 100%;
  box-sizing: initial !important;
}

*,
*::before,
*::after {
  box-sizing: initial !important;
}

body {
  margin: 0 auto;
}

.head-container {
  margin: 0 auto;
  max-width: 1366px !important;
  min-width: 900px !important;
}

img {
  border: none;
}

ol,
ul,
li,
dl,
dd,
dt {
  list-style: none;
}

a {
  text-decoration: none;
  &:link,
  &:visited {
    color: #242424;
  }
}

.head {
  width: 100vw;
  height: 64px;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);

  &-content-box {
    width: 90%;
    z-index: 999;
    height: 64px;
    margin: 0 auto;
    position: relative;
    display: flex;
  }
  .logo-container {
    cursor: pointer;
    width: 130px;
    height: 60px;
    background-color: #fff;
    text-indent: -9999px;
    background: url(./../../assets/images/logo_new.png);
  }
  .nav-container {
    height: 64px;
    overflow: hidden;
    font-size: 16px;
    display: flex;
    align-items: center;

    ul {
      display: flex;
      margin: 0;
    }
    a {
      display: flex;
      padding: 0 3px;
      margin: 0 20px;
      line-height: 64px;
      color: #9d9d9d;
      cursor: pointer !important;

      &.selectedNav {
        border-bottom: 3px solid #3cbc66;
        height: 61px;
        color: #3cbc66;
      }
      &:hover {
        color: #3cbc66;
      }
    }
  }
}

.content-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.userinfo {
  &-container {
    max-width: 120px;
    text-align: center;
    margin-left: 16px;
    line-height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
  }
  &-title,
  &-title a {
    font-size: 14px;
    height: 32px;
    color: #9b9b9b;
    display: flex;
  }
  &-username {
    display: flex;
    align-items: center;
    max-width: 150px;
    min-width: 105px;

    &-content {
      min-width: 65px;
      max-width: 120px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &-vipIcon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
    display: flex;
    img {
      height: 20px;
      width: 20px;
    }
  }
  &-pullIcon {
    margin-left: 5px;
    width: 10px;
    height: 10px;
    display: flex;
    transition: all 0.1s;
    &-i {
      width: 100%;
      height: 100%;
      path {
        fill: #9b9b9b;
      }
    }
    .userinfo-title:hover &-i path {
      fill: #29b758;
    }
    .userinfo-title:hover & {
      transform: rotate(180deg);
      transition: all 0.1s;
    }
  }
  &-submenu {
    display: none;
    position: absolute;
    width: max-content;
    top: 48px;
    right: 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    &-item {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      &:nth-child(1) {
        border-bottom: 1px solid #efefef;
      }
      &:nth-child(2) {
        padding: 5px 0;
      }
      &-menu,
      &-menu a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &-content {
        margin-left: 8px;
        font-size: 14px;
        color: #515151;
      }
      &-icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        &-i {
          width: 100%;
          height: 100%;
          path {
            fill: #515151;
          }
        }
      }
    }
    .superVIP {
      box-sizing: border-box;
      border: 1px solid #29b758;
      padding: 0 10px;
      margin: 16px 16px 10px;
      border-radius: 4px;
      a {
        color: #29b758;
      }
      &:hover {
        cursor: pointer;
        background-color: #29b758;
        a {
          color: #fff;
        }
      }
    }
    .pionts {
      height: 14px;
      padding: 0 16px 10px;
      &:hover {
        cursor: default;
        background-color: #fff;
      }
    }
    .membership,
    .logout {
      height: 32px;
      width: 100%;
      &:hover {
        background-color: #f1f2f3;
      }
    }
  }
  a {
    color: #9d9d9d;
    &:hover {
      color: #3cbc66;
    }
  }
  &-title:hover {
    cursor: pointer;
    .icon_downArrow {
      background-image: url('./../../assets/images/icon1.png');
    }
    .userinfo-username {
      color: #29b758;
    }
    .userinfo-submenu {
      display: block;
    }
  }
}

.search {
  &-container {
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }
  &-input {
    width: 290px;
    input {
      width: 290px;
      background-color: #f4f4f4;
      border-style: none;
      height: 30px;
      line-height: 30px;
      padding-left: 8px;
      outline: none;
    }
  }
  &-iconBox {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 8px;
    top: 7px;
    &:hover {
      cursor: pointer;
    }
    .search-icon {
      width: 100%;
      height: 100%;
      path {
        fill: #515151;
      }
      &:hover path {
        fill: #19af5d;
      }
    }
  }
}

.upload {
  &-btn {
    margin-left: 16px;
    height: 32px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #29b758;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      cursor: pointer;
      background-color: rgba(41, 183, 88, 0.8);
    }
    &:hover &-icon {
      transform: rotate(180deg);
      transition: all 0.1s;
    }
    &-icon {
      margin-left: 6px;
      width: 10px;
      height: 10px;
      display: flex;
      transition: all 0.1s;
      path {
        fill: #fff;
      }
    }
  }
  &-submenu {
    position: absolute;
    left: 0;
    top: 32px;
    height: 64px;
    width: 100%;
    display: none;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    padding: 5px 0;
    z-index: 999;
    &-item {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #f1f2f3;
      }
      &-content {
        margin-left: 8px;
        font-size: 14px;
        color: #515151;
      }
      &-icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        &-i {
          width: 100%;
          height: 100%;
          path {
            fill: #515151;
          }
        }
      }
    }
    .upload-btn:hover & {
      display: block;
    }
  }
}

::-ms-clear,
::-ms-reveal {
  display: none;
}
