.inp1 {
  height: 32px;
  width: 420px;
  line-height: 14px;
  font-size: 14px;
  border: 1px #cccccc solid;
  padding-left: 8px;
  border-radius: 4px;
  background: #f0f0f0;
}
.inp2 {
  height: 32px;
  width: 420px;
  line-height: 14px;
  font-size: 14px;
  border: 1px #cccccc solid;
  padding-left: 8px;
  border-radius: 4px;
  &:focus {
    outline: none;
    border: 1px #19af2d solid;
  }
}

.inputItem {
  margin-top: 20px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #515151;
}
.inputItem .inputTitle {
  width: 60px;
  float: left;
  text-align: right;
}
.inputItem .tipsIcon {
  float: left;
  margin-left: 5px;
  width: 8px;
  height: 32px;
  background-image: url(./../../assets/images/610ee4a.png);
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: center;
}
.inputItem .tipsDlg {
  width: 190px;
  float: right;
  padding-left: 5px;
  color: #ff5732;
}
.display-none {
  display: none;
}
