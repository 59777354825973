.barContainer {
  float: left;
  width: 15vw;
  background: #f5f5f5;
  height: 100%;
  font-weight: bold;
  text-align: center;
}

.barTitle {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #515151;
}

.title-selected {
  .barTitle {
    color: #19af5d;
  }
}

.menu-selected {
  display: block;
  background: white;

  & .menu {
    position: relative;
    color: #19af5d;
  }

  & .selectedArrow {
    width: 14px;
    height: 40px;
    background-image: url(./../../assets/images/320b7.png);
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center;
    position: absolute;
    top: 0;
    right: 10px;
  }
}

.memberside-menu {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #515151;
  cursor: pointer;
  display: block;
}

.menu2:hover {
  color: #19af5d;
}
